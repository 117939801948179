<template>
    <div>
        <el-row>
            <el-col :span="24">
                <div class="h-handle-button">
                    <div class="h-b">
                        <el-button type="primary" size="small" @click="addParam" v-if="isCanEdit">新增</el-button>
                    </div>
                    <div class="h-b" style="">
                        <el-button type="primary" size="small" @click="fromContent" v-if="isCanEdit">报文解析</el-button>
                    </div>
                    <div class="h-b" style="">
                        <el-button type="primary" size="small" @click="toContent">生成报文</el-button>
                    </div>
                </div>
                <el-table :data="paramsList" row-key="Id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                    style="width: 100%;margin-bottom: 20px;" default-expand-all>
                    <el-table-column type="index" width="50" align="center"></el-table-column>
                    <el-table-column prop="ParamCode" label="参数代码">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.ParamCode" placeholder="参数代码必填"></el-input>
                            <!-- <span>{{scope.row.ParamCode}}</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="ParamName" label="参数名称" align="right">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.ParamName" placeholder="参数名称"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ParamValueType" label="数据类型" align="center" min-width="60">
                        <template slot-scope="scope">
                            <DictionarySelect v-model="scope.row.ParamValueType" dicTypeCode="ApiFieldTypeCode"
                                datType="Int">
                            </DictionarySelect>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="IsSign" label="参与验签" align="center">
                        <template slot-scope="scope">
                            <el-switch v-model.trim="scope.row.IsSign"></el-switch>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="PositionType" label="参数位置" align="center" >
                                <template slot-scope="scope">
                                    <DictionarySelect v-model="scope.row.PositionType" dicTypeCode="ApiParamPositionTypeCode" datType="Int">
                                    </DictionarySelect>
                                </template>
                            </el-table-column> :show-all-levels="false"-->
                    <el-table-column prop="MapFiledCode" label="数据映射字段" align="center">
                        <template slot-scope="scope">
                            <el-cascader v-model="scope.row.MapFiledCodePath" :options="fieldSelectOptions"
                                :props="{ checkStrictly: true, emitPath: true }" clearable filterable
                                @change="mapFiledChange(scope.row)"></el-cascader>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ConvertType" label="转换方式" align="center"  :render-header="convertTipRender">
                        <template slot-scope="scope">
                            <DictionarySelect v-model="scope.row.ConvertType" dicTypeCode="ApiConvertTypeCode"
                                datType="Int">
                            </DictionarySelect>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CustomConvert" label="自定义值" align="center" width="180">
                        <template slot-scope="scope">

                            <el-input v-model="scope.row.CustomConvert" placeholder="自定义值"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" v-if="isCanEdit">
                        <template slot-scope="scope">
                            <el-button type="text" @click="insertAt(scope.row)">插入</el-button>
                            <el-button type="text" @click="deleteParam(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <DialogEx :options="fromContentOptions" title="解析报文" @onOk="okFromContent">
            <div>
                <el-form>
                    <el-form-item>
                        <el-col :span="4" class="form-title">请求报文：</el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 20 }"
                                    v-model.trim="paramContent" placeholder="请输入请求报文"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="4" class="form-title">报文类型：</el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-select v-model.trim="contentType">
                                    <el-option v-for="item in contentTypeList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <p style="text-align: right;">
                                <el-link type="primary" @click="jonsDeal">格式化</el-link>
                            </p>
                        </el-col>
                    </el-form-item>
                </el-form>
            </div>
        </DialogEx>

        <DialogEx :options="paramsToContentOptions" title="生成报文" @onCompleted="okToContent">
            <div>
                <el-form>
                    <el-form-item>
                        <el-col :span="4" class="form-title">报文：</el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 20 }"
                                    v-model.trim="paramContent"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                </el-form>
            </div>
        </DialogEx>
        <DialogEx :options="tipsOptions" title="参数转换方式说明">
        <div class="tip-content">
            <p>转换方式：</p>
            <p>时间转换：示例自定义值：yyyy-MM-dd HH:mm:ss，具体格式看平台字段对应格式是什么；   </p>
            <p>枚举转换：示例：{"源值1":"目标值1","源值2":"目标值2"}，JSON格式字符串。</p>
            <br>
            <p>表达式：</p>
            <p>目前表达式转换方式支持一下几种函数或语法类型：</p>
            <p>1. 绝对值： <strong>$ABS()</strong>，对当前字段求绝对值；</p>
            <p>2. 字符串截取：<strong>$SUBSTRING(beginIndex,endIndex)</strong> 。beginIndex:起始位置，endIndex结束位置，示例：$SUBSTRING(0,10)；</p>
            <p>3. 正则提取：<strong>$SUBSTRINGREGEX(regex)</strong>，例如 $SUBSTRINGREGEX(^((13[0-9])|(14[5,7])$)；</p>
            <p>4. 转换为数字：<strong>$PARSENUMBER()</strong>,把当前字段转换为数字</p>
            <p>5. 除法：<strong>$DIV(num,scale)</strong>,将当前字段做除法运算，num除数，scale为小数位数。示例：$DIV(2,2) 当前字段除以2，保留两位小数</p>
            <p>6. 右边拼接字符串：<strong>$CONCATRIGHT(str)</strong>；</p>
            <p>7. 左边拼接字符串：<strong>$CONCATLEFT(str)</strong>；</p>
            <p>8. 覆盖当前字段值：<strong>$OVERRIDE(val)</strong>,val为覆盖后的值，也可以用作字段的默认值；</p>
            <p>9. 从其他参数覆盖当前字段值并执行转换规则：<strong>$OVERRIDEFROMPARAM(val,convertType,convertExpress)</strong> ,val其他参数值，   
                convertType转换方式（1不需要转换，2时间转换，3枚举转换，4表达式，5系统公共映射，6系统接入映射），convertExpress自定义值；示例：$OVERRIDEFROMPARAM(data.warehouseKey,5,WarehouseIdMapCarrier)</p>
            <p>10.三元运算符：<strong>需要比较的值?值1:值2</strong>,判断当前字段值和需要的比较的值是否相等。示例：200?true:false,如果当前字段值为200，则转换为true。</p>
            <p></p>
            <br>
            <p>系统公共映射：根据系统定义里面的公共映射进行转换，【自定义值】则填写【映射编码】值；</p>
            <p>系统接入映射：根据租户端系统接入里面的映射信息进行转换，【自定义值】则填写【映射键】值；</p>
        </div>
        </DialogEx>
    </div>
</template>
<script>
export default {
    mounted() { },
    data() {
        return {
            isJsonBuef: true,
            fieldSelectOptions: [],
            paramIndex: 0,
            fromContentOptions: {
                visible: false,
                size: 'small',
            },
            paramsToContentOptions: {
                visible: false,
                size: 'small',
            },
            paramContent: null,
            contentType: 'json',
            contentTypeList: [
                { value: 'json', label: 'json' },
                { value: 'xml', label: 'xml' },
            ],
            tipsOptions:{
                visible: false,
                size: 'small',
            },
        }
    },
    props: {
        paramsList: [],
        positionType: {
            type: Number,
            default: 3
        },
        entityName: {
            type: String,
            default: ''
        },
        isCanEdit: {
            type: Boolean,
            default: true
        },
        transferApiId:{
            type: String,
            default: null
        },
    },
    watch: {
        entityName: {
            handler: function (val, oldval) {
                if (val) {
                    this.getSelectMapFieldList(val);
                }
            },
            deep: true
        },
        transferApiId:{
            handler: function (val, oldval) {
                if (val) {
                    this.getTransferApiParamList(val,this.positionType);
                }
            },
            deep: true
        }
    },
    methods: {
        convertTipRender(h, { column, $index }){
            // h 是 createElement 方法，用于创建 Vue 组件或 DOM 元素
            return h('div', [
                h('strong', column.label), // strong 标签包裹文字
                h('i',{class:['el-icon-question'],on:{click:this.showConvertTips},style: {cursor: 'pointer'}}), // 显示原有的列标签
            ]);
        },
        showConvertTips(){
            this.tipsOptions.visible = true;
            console.log(this.tipsOptions)
        },
        mapFiledChange(row) {
            console.log(row);
            if (row.MapFiledCodePath) {
                row.MapFiledCode = row.MapFiledCodePath.join();
            }
        },
        addParam() {
            this.paramIndex++;
            var newRow = {
                Id: this.paramIndex,
                ParamCode: '',
                ParamName: '',
                PositionType: this.positionType,
                ParamValueType: 1,
                IsSign: false,
                IsRequired: false,
                ConvertType: 1,
                children: [],
            };
            this.paramsList.push(newRow)
        },
        fromContent() {
            this.fromContentOptions.visible = true;
        },
        toContent(){
            var _this = this;
            _this.$ajax.send("omsapi/apiinfo/apiParamsToString", "post", { ApiParamDTOList: _this.paramsList, ContentType: 'json'}, (data) => {
                if (data.IsSuccess) {
                    _this.paramContent = data.Result;
                    _this.paramsToContentOptions.visible=true;
                    _this.jonsDeal();
                }else{
                _this.Utils.messageBox("操作失败.", "error");
                }
            });
        },
        okFromContent() {
            var _this = this;
            if (!_this.paramContent) {
                _this.Utils.messageBox("请输入报文.", "error");
                return;
            }
            _this.$ajax.send("omsapi/apiinfo/parseToApiParams", "post", { Content: _this.paramContent, ContentType: _this.contentType, PositionType: _this.positionType }, (data) => {
                if (data.IsSuccess) {
                    _this.paramContent = null;
                    _this.paramsList.splice(0, _this.paramsList.length);
                    for (var i = 0; i < data.Result.length; i++) {
                        _this.paramsList.push(data.Result[i]);
                    }
                }
            });
        },
        okToContent(){
            this.paramContent = null;
        },
        getSelectMapFieldList(name) {
            var _this = this;
            this.$ajax.query("omsapi/apiinfo/getEntityFieldList", "get", { entityName: name }, (data) => {
                if (data.IsSuccess) {
                    _this.fieldSelectOptions = _this.Utils.iterationDelateMenuChildren(data.Result);
                } else {
                    _this.Utils.messageBox("获取数据失败.", "error");
                }
            });
        },
        getTransferApiParamList(id,pType) {
            var _this = this;
            this.$ajax.query("omsapi/apiinfo/getTransferApiParamList", "get", { transferApiId: id,positionType: pType}, (data) => {
                if (data.IsSuccess) {
                    _this.fieldSelectOptions = _this.Utils.iterationDelateMenuChildren(data.Result);
                } else {
                    _this.Utils.messageBox("获取数据失败.", "error");
                }
            });
        },
        insertAt(row) {
            this.paramIndex++;
            var newRow = {
                Id: this.paramIndex,
                ParamCode: '',
                ParamName: '',
                PositionType: this.positionType,
                ParamValueType: 1,
                IsSign: false,
                IsRequired: false,
                ParentParamId: row.Id,
                ConvertType: 1,
                children: [],
            };
            row.children.push(newRow);
            console.log(this.paramsList)
        },
        deleteParam(row) {
            console.log(row)
            if (row.ParentParamId) {
                var parentParam = this.searchParamByTree(row.ParentParamId, this.paramsList);
                if (parentParam && parentParam.children) {
                    parentParam.children.remove(row);
                }
            } else {
                this.paramsList.splice(this.paramsList.indexOf(row), 1);
            }
        },
        searchParamByTree(paramId, searchParamList) {
            let apiParam = null;
            for (var i = 0; i < searchParamList.length; i++) {
                if (searchParamList[i].Id == paramId) return searchParamList[i];
                if (searchParamList[i].children) {
                    apiParam = this.searchParamByTree(paramId, searchParamList[i].children);
                }
                if (apiParam) {
                    break;
                }
            }
            return apiParam;
        },
        jonsDeal() {
            var _this = this;
            if (this.contentType === 'json') {
                try {
                    var jsonObj = JSON.parse(_this.paramContent || '');
                    _this.paramContent = JSON.stringify(jsonObj, null, 4);
                } catch (e) {
                    _this.paramContent = _this.paramContent;
                }
                try {
                    jsonObj = JSON.parse(_this.responseContent || '');
                    _this.responseContent = JSON.stringify(jsonObj, null, 4);

                } catch (e) {
                    _this.responseContent = _this.responseContent;
                }
            }
        },
    }
}
</script>
<style scoped>
.tip-content p{line-height: 150%;}
</style>